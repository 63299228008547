import { useCallback } from "react";
import classnames from "classnames";
import pluralize from "pluralize";

import { DISPENSARY_NEW_FILTER_STRINGS } from "constants/dispensary";
import useFilters from "hooks/useFilters";
import { scrollToTop } from "utils/scrollPosition";

import Dropdown from "components/botanic/Dropdown";
import DropdownItem from "components/botanic/Dropdown/DropdownItem";
import ActiveFilterTags from "components/Dispensary/MenuFilters/ActiveFilterTags";

import usePublishFilterClickEvent from "../../MenuFilters/hooks/usePublishFilterClickEvent";
import useSortDropDown from "../hooks/useSortDropDown";

const DispensarySortDropDown: React.FC<{
  availableSorts: string[];
  className?: string;
  totalItems: number;
}> = ({ availableSorts, className, totalItems }) => {
  const { selectFilterValue, addDispensaryFilter, filterValues } = useFilters();
  const { selectMenuSorts } = useSortDropDown(availableSorts);
  const { publishSortDropDownClick } = usePublishFilterClickEvent();
  const sortValue = selectFilterValue("sort");

  const handleClick = useCallback(
    (value: string) => {
      addDispensaryFilter(selectMenuSorts.name, value, {
        boost_strain: undefined,
      });
      publishSortDropDownClick(value);

      scrollToTop("smooth");
    },
    [publishSortDropDownClick, scrollToTop, addDispensaryFilter],
  );

  return (
    <div
      className={classnames("flex justify-between items-start", className)}
      data-testid="filter-drop-down"
    >
      <div className="flex items-start">
        {totalItems > 0 && (
          <div className="flex-shrink-0 font-bold mr-xxl">
            {pluralize("Product", totalItems, true)}
          </div>
        )}
        <ActiveFilterTags
          activeFilters={filterValues}
          wrap
          className="hidden lg:flex"
        />
      </div>

      <div
        data-testid="sort-dropdown"
        className="flex flex-shrink-0 items-center justify-center text-xs ml-lg"
      >
        Sort by&nbsp;
        <Dropdown
          className="items-center"
          selectedItem={
            DISPENSARY_NEW_FILTER_STRINGS[sortValue as string] || "Recommended"
          }
        >
          <DropdownItem
            key="sort-recommended"
            className="p-md z-10"
            onClick={() => handleClick("recommended")}
          >
            Recommended
          </DropdownItem>
          <>
            {(selectMenuSorts.values || []).map(({ value }) => (
              <DropdownItem
                key={`sort-${value}`}
                className="p-md z-10 whitespace-nowrap"
                onClick={() => handleClick(value)}
              >
                {`${DISPENSARY_NEW_FILTER_STRINGS[value]}`}
              </DropdownItem>
            ))}
          </>
        </Dropdown>
      </div>
    </div>
  );
};

export default DispensarySortDropDown;
