import React from "react";
import classname from "classnames";
import { useRouter } from "next/router";

import GoogleAd from "components/GoogleAd";

type Props = {
  page?: "search" | "store";
  tag: "upper" | "lower" | "availability" | "menu_upper";
  customTargeting?: Record<string, string | string[] | undefined>;
  className?: string;
  idOverride?: string;
};

const SearchAd: React.FC<Props> = ({
  page = "search",
  tag,
  customTargeting = {},
  className,
  idOverride,
}) => {
  const {
    query: { q, brandReferral },
  } = useRouter();

  if (brandReferral) {
    return null;
  }

  const targeting: { [key: string]: string } = {};
  if (q) {
    targeting.searchTerm = String(q);
  }

  return (
    <div
      className={classname(
        "flex items-center w-full justify-center gam-rectangular-fixed-height",
        className,
      )}
    >
      <GoogleAd
        idOverride={idOverride}
        adPath={`/13212770/${page}/${tag}`}
        sizes={{ desktop: [728, 90], mobile: [320, 50] }}
        targeting={{
          ...targeting,
          ...customTargeting,
        }}
      />
    </div>
  );
};

export default SearchAd;
