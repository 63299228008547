import React, { useCallback } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { STRAIN_USER_REVIEW_DISCLAIMER } from "constants/disclaimers";
import useFilters from "hooks/useFilters";
import useIsStrainListPage from "hooks/useIsStrainListPage";
import { getAreCategoryCoinIconsHidden } from "redux/selectors/complianceRules";

import Switch from "components/botanic/Switch/Switch";
import ExpandableFilterOptions from "components/ExpandableFilterOptions";
import LabelWithBadge from "components/Filters/FilterCheckbox/LabelWithBadge";
import TerpeneLabel from "components/Filters/FilterCheckbox/TerpeneLabel";
import FilterCheckboxList, {
  FilterCheckboxOption,
} from "components/Filters/FilterCheckboxList";
import FilterSearchList from "components/Filters/FilterSearchList";
import { FilterSection } from "components/Filters/FilterSection";
import { FilterTile } from "components/Filters/FilterTile";
import IconSvg from "components/IconSvg";
import MedicalDisclaimers from "components/MedicalDisclaimers";

import useIcons from "../../hooks/useIcons";
import useMenuFilterOptions from "../../hooks/useMenuFilterOptions";
import usePublishFilterClickEvent from "../../hooks/usePublishFilterClickEvent";
import {
  MenuFilterGroupTypes,
  MenuFilterMap,
  MenuFilterOptions,
  MenuFiltersGroup,
  MenuFiltersToggle,
  MenuItemFilterSection,
  MenuPage,
} from "../../types/MenuFilters";

const DEFAULT_VISIBLE_TAKE = 9;

const DynamicFilter: React.FC<{
  filters: MenuFiltersGroup | MenuFiltersToggle;
  menuFilterMap: MenuFilterMap;
  defaultOpenKey: string | undefined;
  menuPage: MenuPage;
  loading?: boolean;
  shallowRouting?: boolean;
}> = ({
  filters,
  menuFilterMap,
  defaultOpenKey,
  menuPage,
  loading,
  shallowRouting = true,
}) => {
  const { toggleFilter } = useFilters({ shallowRouting });
  const { publishFilterClickEvent } = usePublishFilterClickEvent(menuPage);
  const onFilterClick = useCallback(
    (filterName: string, filterOption: string, filterLabel: string) => {
      toggleFilter({ [`${filterName}[]`]: filterOption });
      publishFilterClickEvent(filterLabel, filterOption, filterName);
    },
    [toggleFilter, publishFilterClickEvent],
  );

  return (
    <>
      {filters.type === MenuFilterGroupTypes.toggle && (
        <ToggleFilter
          filter={filters as MenuFiltersToggle}
          menuPage={menuPage}
        />
      )}
      {filters.type === MenuFilterGroupTypes.tileGroup &&
        (filters as MenuFiltersGroup).values.length > 0 && (
          <TileFilters
            filters={filters as MenuFiltersGroup}
            menuFilterMap={menuFilterMap}
            defaultOpenKey={defaultOpenKey}
            menuPage={menuPage}
            onFilterClick={onFilterClick}
            loading={loading}
          />
        )}
      {filters.type === MenuFilterGroupTypes.checkBoxGroup &&
        (filters as MenuFiltersGroup).values.length > 0 && (
          <CheckboxFilters
            filters={filters as MenuFiltersGroup}
            menuFilterMap={menuFilterMap}
            defaultOpenKey={defaultOpenKey}
            menuPage={menuPage}
            onFilterClick={onFilterClick}
            loading={loading}
          />
        )}
    </>
  );
};

const ToggleFilter: React.FC<{
  filter: MenuFiltersToggle;
  menuPage: MenuPage;
}> = ({
  filter: {
    name,
    label,
    sublabel,
    filterIcon,
    labelIcon,
    count = 0,
    showCount = false,
  },
  menuPage,
}) => {
  const { filterValues, toggleFilter } = useFilters();
  const { getIconPath } = useIcons();
  const { publishFilterClickEvent } = usePublishFilterClickEvent(menuPage);
  const checked = name in filterValues;
  const disabled = count < 1;
  const delivery = menuPage === MenuPage.delivery;

  const onToggleSelect = () => {
    toggleFilter({ [name]: "true" });
    publishFilterClickEvent(label, "true", name);
  };

  const labelIconClassnames = classNames("self-center", {
    "text-verified": name === MenuItemFilterSection.BrandVerified,
  });

  // * This is a temporary change as there is currently no good styling for
  // * disabled toggle filters. This will remove toggle filters with 0
  // * results from the UI for now, until we have a better UI for disabled states.
  // * https://leafly.atlassian.net/browse/B2B-733
  if (disabled) {
    return null;
  }

  return (
    <div
      className={classNames(
        "flex flex-col border-t border-b border-light-grey p-4 md:pt-lg md:px-0 md:gap-2",
        {
          "md:pb-lg": sublabel,
        },
      )}
    >
      <div className="flex justify-between" data-testid="toggle-filter">
        <span
          className={classNames("flex font-bold ", {
            // "text-start leading-none": sublabel,
          })}
        >
          {filterIcon &&
            !delivery &&
            name !== MenuItemFilterSection.BrandVerified && (
              <IconSvg
                height="24"
                width="24"
                className="flex-shrink-0 inline mr-xs"
                filePath={getIconPath(name, filterIcon)}
                testId={`${name}-filter-icon`}
              />
            )}
          {label}&nbsp;
          {labelIcon && (
            <IconSvg
              height="16"
              width="16"
              className={labelIconClassnames}
              filePath={getIconPath(name, labelIcon)}
            />
          )}
          {showCount && (
            <span
              className={classNames(
                "text-grey text-start leading-none self-center",
                labelIcon ? "pl-1" : "",
              )}
            >
              ({count.toLocaleString("en-us")})
            </span>
          )}
        </span>
        <Switch
          checked={checked}
          disabled={disabled}
          onChange={onToggleSelect}
          screenReaderText={label}
        />
      </div>
      {sublabel && (
        <div className="flex items-center">
          <span className="text-xs font-normal whitespace-pre-wrap text-left text-grey">
            {sublabel}
          </span>
        </div>
      )}
    </div>
  );
};

const HIDE_SEARCH = ["product_category", "strain_top_terp"];

const CheckboxFilters: React.FC<{
  filters: MenuFiltersGroup;
  menuFilterMap: MenuFilterMap;
  defaultOpenKey: string | undefined;
  menuPage: MenuPage;
  onFilterClick: (
    filterName: string,
    filterOption: string,
    filterLabel: string,
  ) => void;
  loading?: boolean;
}> = ({
  filters: { label, name },
  menuFilterMap,
  defaultOpenKey,
  menuPage,
  onFilterClick,
  loading,
}) => {
  const { selectFilterValues } = useFilters();
  const { publishFilterSectionClickEvent } =
    usePublishFilterClickEvent(menuPage);

  const options = useMenuFilterOptions(menuFilterMap, name);

  const { isStrainListPageFilter, isStrainListPageNameEqualToFilterValue } =
    useIsStrainListPage();

  return (
    <FilterSection
      title={label}
      numberOfItemsSelected={
        selectFilterValues(name).length + (isStrainListPageFilter(name) ? 1 : 0)
      }
      openDefault={defaultOpenKey === name}
      onFilterSectionClick={publishFilterSectionClickEvent}
    >
      {name === "helps_with" && (
        <MedicalDisclaimers usDisclaimer={STRAIN_USER_REVIEW_DISCLAIMER} />
      )}
      {HIDE_SEARCH.includes(name) ? (
        <FilterCheckboxList
          onChange={(option) => {
            if (onFilterClick) {
              onFilterClick(name, option.value, option.label);
            }
          }}
          selected={(option) =>
            selectFilterValues(name).includes(option.value) ||
            isStrainListPageNameEqualToFilterValue(option.value)
          }
          options={options}
          disabled={(option) =>
            isStrainListPageNameEqualToFilterValue(option.value)
          }
          loading={loading}
          name={name}
          renderCustomLabel={(
            filter: FilterCheckboxOption & { badge?: string },
          ) => (
            <DynamicBadge
              displayName={filter.displayName}
              badge={filter?.badge ?? ""}
              name={name}
            />
          )}
        />
      ) : (
        <FilterSearchList
          label={label}
          name={name}
          loading={loading}
          options={options}
          onChange={(option) => {
            if (onFilterClick) {
              onFilterClick(name, option.value, option.label);
            }
          }}
          selected={(option) =>
            selectFilterValues(name).includes(option.value) ||
            isStrainListPageNameEqualToFilterValue(option.value)
          }
          disabled={(option) =>
            isStrainListPageNameEqualToFilterValue(option.value)
          }
          renderCustomLabel={(
            filter: FilterCheckboxOption & { badge?: string },
          ) => (
            <DynamicBadge
              displayName={filter.displayName}
              badge={filter?.badge ?? ""}
              name={name}
            />
          )}
        />
      )}
    </FilterSection>
  );
};

const TileFilters: React.FC<{
  filters: MenuFiltersGroup;
  menuFilterMap: MenuFilterMap;
  defaultOpenKey: string | undefined;
  menuPage: MenuPage;
  onFilterClick: (
    filterName: string,
    filterOption: string,
    filterLabel: string,
  ) => void;
  visibleTake?: number;
  loading?: boolean;
}> = ({
  filters: { label, name },
  menuFilterMap,
  defaultOpenKey,
  menuPage,
  onFilterClick,
  visibleTake = DEFAULT_VISIBLE_TAKE,
  loading,
}) => {
  const { selectFilterValues } = useFilters();
  const { publishFilterSectionClickEvent } =
    usePublishFilterClickEvent(menuPage);
  const options = useMenuFilterOptions(menuFilterMap, name);
  const visibleOptions = options.slice(0, visibleTake);
  const hiddenOptions = options.slice(visibleTake);

  const { isStrainListPageFilter } = useIsStrainListPage();

  return (
    <FilterSection
      title={label}
      numberOfItemsSelected={
        selectFilterValues(name).length + (isStrainListPageFilter(name) ? 1 : 0)
      }
      onFilterSectionClick={publishFilterSectionClickEvent}
      openDefault={defaultOpenKey === name}
    >
      <div className="row" data-testid="tile-filters">
        <div className="flex flex-wrap overflow-auto max-h-80">
          <DynamicTileFilter
            options={visibleOptions}
            selectFilterValues={selectFilterValues}
            onFilterClick={onFilterClick}
            loading={loading}
            name={name}
          />
          {!!hiddenOptions.length && (
            <ExpandableFilterOptions
              expandButtonText={`Show all ${options.length.toLocaleString(
                "en-us",
              )}`}
              className="ml-sm"
            >
              <DynamicTileFilter
                options={hiddenOptions}
                loading={loading}
                onFilterClick={onFilterClick}
                selectFilterValues={selectFilterValues}
                name={name}
              />
            </ExpandableFilterOptions>
          )}
        </div>
      </div>
    </FilterSection>
  );
};

const DynamicTileFilter: React.FC<{
  options: MenuFilterOptions[];
  selectFilterValues: (name: string) => string[];
  name: MenuItemFilterSection;
  onFilterClick?: (filterName: string, id: string, filterLabel: string) => void;
  loading?: boolean;
}> = ({ options, selectFilterValues, onFilterClick, name, loading }) => {
  const { getIconPath } = useIcons();
  const hideCategoryIcons = useSelector(getAreCategoryCoinIconsHidden);

  const { isStrainListPageNameEqualToFilterValue } = useIsStrainListPage();

  return (
    <>
      {options?.map(({ label, value, docCount, showCount }) => (
        <div className="col-1/3 mb-sm" key={value}>
          <FilterTile
            loading={loading}
            iconPath={
              name === "strain_category" && hideCategoryIcons
                ? null
                : getIconPath(name, value)
            }
            label={label}
            onClick={() => {
              if (onFilterClick) {
                onFilterClick(name, `${value}`, label);
              }
            }}
            lowerCase
            count={docCount}
            showCount={showCount}
            selected={
              selectFilterValues(name).includes(value) ||
              isStrainListPageNameEqualToFilterValue(value)
            }
            disabled={isStrainListPageNameEqualToFilterValue(value)}
          />
        </div>
      ))}
    </>
  );
};

const DynamicBadge: React.FC<{
  displayName: string;
  badge: string;
  name: MenuItemFilterSection;
}> = ({ displayName, badge, name }) => (
  <>
    {name === MenuItemFilterSection.Terpenes ? (
      <TerpeneLabel
        displayName={displayName}
        colorClassName={`bg-${displayName.toLowerCase()} mr-2`}
      />
    ) : (
      <LabelWithBadge displayName={displayName} badge={badge} />
    )}
  </>
);

export default DynamicFilter;
