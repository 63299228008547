import React, { useContext, useMemo } from "react";
import { useRouter } from "next/router";

import { STRAIN_USER_REVIEW_DISCLAIMER } from "constants/disclaimers";
import { DISPENSARY_FILTER_NAMES } from "constants/dispensary";
import MenuContext from "context/MenuContext";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

import SimilarEffectsIcon from "components/Icons/similar-effects.svg";
import TooltipCTA from "components/StrainPage/TooltipCTA";

const StrainEffectCopy = () => {
  const {
    selectors: { menuItems },
  } = useContext(MenuContext);
  const { asPath } = useRouter();
  const { query: parsedQuery } = parseNextRouterAsPath(asPath);
  const { boost_strain } = parsedQuery;

  const activeFilters = useMemo(() => {
    // Remove any query params that shouldn't count towards total
    const { _page, _sort, _q, ...filters } = parsedQuery;

    return Object.entries(filters)
      .filter(([name]) => Object.keys(DISPENSARY_FILTER_NAMES).includes(name))
      .map(([name, value]) => ({
        name,
        values: Array.isArray(value) ? value : [value],
      }));
  }, [parsedQuery]);

  const hasStrainEffectFilters = !!activeFilters.find(
    (filter) => !!filter.name && filter.name === "top_strain_effects_included",
  );

  const hasOtherFilters = !!activeFilters.find(
    (filter) =>
      !!filter.name &&
      !["boost_strain", "top_strain_effects_included"].includes(filter.name),
  );

  //Hide the similar effects shopping XP if the user has applied other filters or removed them entirely
  if (!hasStrainEffectFilters || !boost_strain || hasOtherFilters) {
    return null;
  }

  const hasMatchedProducts = menuItems?.find(
    (item) => item.strainName === boost_strain,
  );

  const header = hasMatchedProducts
    ? `Shop ${boost_strain} & hand-picked products with similar effects`
    : `${boost_strain} is not available here`;

  return (
    <div className="p-xs">
      <h3>{header}</h3>
      {!hasMatchedProducts && (
        <span className="text-xs">
          Shop budtender recommendations with similar effects
        </span>
      )}
      <div className="my-md">
        <TooltipCTA
          title="Similar strains have similar effects"
          icon={<SimilarEffectsIcon width="25" height="25" />}
          sectionName="similar-strains"
          maxWidth="∞" // No max width
        >
          <>
            <p>{STRAIN_USER_REVIEW_DISCLAIMER}</p>
          </>
        </TooltipCTA>
      </div>
    </div>
  );
};

export default StrainEffectCopy;
