import React from "react";

import { GetMenuItemsAvailableFilters } from "api/requests/getMenuItems";

import { Facet, FilteredGrid } from "components/FilteredGrid";
import { FilterChips } from "components/FilteredGrid/FilterChips";
import DesktopFilterPlaceholder from "components/MenuFilters/DesktopFilterPlaceholder";

import {
  useAPISupportedFilterFacets,
  useChips,
  useNonAPISupportedFilterFacets,
} from "./hooks";
import MenuFilterGroups from "./MenuFilterGroups";
import MobileMenuFilterShortcuts from "./MobileMenuFilterShortcuts";
import { MenuPage } from "./types/MenuFilters";

const MenuFilters: React.FC<{
  allowNonAPISupportedFacets?: boolean;
  availableFilters: GetMenuItemsAvailableFilters;
  children: React.ReactNode;
  renderHeader?: () => React.ReactNode;
  menuPage?: MenuPage;
  loading?: boolean;
  mobileFilterPanelImpression?: { [key: string]: string | boolean };
  onClearAllClick?: (facet?: Facet) => void;
  shallowRouting?: boolean;
  mobileClassName?: string;
  ignoredQueries?: string[];
}> = ({
  allowNonAPISupportedFacets,
  availableFilters,
  children,
  renderHeader,
  menuPage = MenuPage.dispensary,
  loading,
  onClearAllClick,
  mobileFilterPanelImpression,
  shallowRouting,
  mobileClassName,
  ignoredQueries = [],
}) => {
  const { handleFilterChipClick } = useChips({ shallowRouting });

  const menuFilterMap = availableFilters.reduce(
    (filterMap, availableFilter) => ({
      ...filterMap,
      [availableFilter.name]: availableFilter,
    }),
    {},
  );

  const apiSupportedFacets = useAPISupportedFilterFacets(
    menuFilterMap,
    ignoredQueries,
  );

  const nonAPISupportedFacets = useNonAPISupportedFilterFacets(
    menuFilterMap,
    ignoredQueries,
  );

  return (
    <div data-testid="menu-filter-container">
      <FilteredGrid
        confirmationButtonText="confirm"
        apiSupportedFacets={
          allowNonAPISupportedFacets
            ? [...apiSupportedFacets, ...nonAPISupportedFacets]
            : apiSupportedFacets
        }
        renderHeader={renderHeader}
        menuPage={menuPage}
        onClearAllClick={onClearAllClick}
        mobileClassName={mobileClassName}
        renderFilters={(_facets, defaultOpenKey) =>
          availableFilters.length > 0 ? (
            <MenuFilterGroups
              availableFilters={availableFilters}
              menuFilterMap={menuFilterMap}
              defaultOpenKey={defaultOpenKey}
              menuPage={menuPage}
              loading={loading}
              shallowRouting={shallowRouting}
            />
          ) : (
            <DesktopFilterPlaceholder />
          )
        }
        renderChips={(facets: Facet[]) => (
          <FilterChips
            facets={facets}
            onClick={(facet: Facet) =>
              onClearAllClick
                ? onClearAllClick(facet)
                : handleFilterChipClick(facet)
            }
          />
        )}
        availableFilters={availableFilters}
        mobileFilterPanelImpression={mobileFilterPanelImpression}
        mobileShortcuts={MobileMenuFilterShortcuts}
      >
        {children}
      </FilteredGrid>
    </div>
  );
};

export default MenuFilters;
