import { useContext, useEffect, useMemo, useRef } from "react";
import { useRouter } from "next/router";

import { Placement } from "api/requests/getBrandPlacement";
import { DealBanner } from "api/requests/getDealBanner";
import { DISPENSARY_MEDICAL_DISCLAIMER } from "constants/disclaimers";
import MenuContext from "context/MenuContext";
import { Deal } from "custom-types/Deals";
import { Dispensary } from "custom-types/Dispensary";
import { MedicalIdCondition } from "custom-types/MedicalIdCondition";
import { MenuItem } from "custom-types/MenuItem";
import useFilters from "hooks/useFilters";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";
import { scrollToTop } from "utils/scrollPosition";

import ActiveDealsCarousel from "components/Dispensary/ActiveDealsCarousel";
import CategoryCarousel from "components/Dispensary/CategoryCarousel";
import MedicalInfo from "components/Dispensary/MedicalInfo";
import PromotedBrandCarousel from "components/Dispensary/PromotedBrandCarousel";
import SearchBar from "components/Dispensary/SearchBar";
import MedicalDisclaimers from "components/MedicalDisclaimers";
import MenuFilters from "components/MenuFilters";
import PageNavigation from "components/PageNavigation";
import SearchAd from "components/SearchAd";

import { DealsBanner } from "../DealsBanner/DealsBanner";
import DispensaryMenuItems from "../DispensaryMenuItems";
import LastUpdatedTime from "../LastUpdatedTime";
import MenuSortDropDown from "../MenuSortDropDown";
import StaffPicksCarousel from "../StaffPicksCarousel";
import StrainEffectCopy from "./StrainEffectCopy";

const MenuContainer: React.FC<{
  brandPlacement: Placement | null;
  deals: Deal[];
  dealsBanner: DealBanner | null;
  dispensary: Dispensary;
}> = ({ brandPlacement, deals, dealsBanner, dispensary }) => {
  const { asPath } = useRouter();
  const { query: parsedQuery } = parseNextRouterAsPath(asPath);
  const { addDispensaryFilter, filtersApplied, commonSearchParamsApplied } =
    useFilters();

  const {
    dispatch: { getMenuItems },
    selectors: {
      availableFilters,
      availableSorts,
      loading,
      menuItems,
      totalItems,
      totalPages,
    },
  } = useContext(MenuContext);

  const isCanadianDispensary = dispensary.country === "CA";

  const isMedIdRequired =
    dispensary.orderMedIdCondition === MedicalIdCondition.Required;

  const isMounted = useRef(false);
  useEffect(() => {
    // This check is here to prevent a redundant call to fetch data on initial mount.
    // We only want to fetch new data when dependencies change.
    if (isMounted.current) {
      getMenuItems();
    }

    isMounted.current = true;
  }, [JSON.stringify(parsedQuery)]);

  const hasActiveFilter = useMemo(() => {
    return filtersApplied || commonSearchParamsApplied;
  }, [loading]);

  const page = parseInt(parsedQuery.page ? String(parsedQuery.page) : "1", 10);

  const handleUpdatePage = (newPage: number) => {
    addDispensaryFilter("page", String(newPage));
    setTimeout(scrollToTop, 100);
  };

  return (
    <div className="pb-section">
      <div className="container pt-lg">
        <SearchBar
          className="mt-xl mb-lg"
          dispensaryId={dispensary.id}
          dispensaryRetailType={dispensary.retailType}
          dispensarySlug={dispensary.slug}
          dispensaryTags={dispensary.tags}
        />
        {isMedIdRequired && (
          <MedicalInfo
            dispensaryId={dispensary.id}
            dispensaryRetailType={dispensary.retailType}
            dispensaryTags={dispensary.tags}
          />
        )}
      </div>
      <div className="mt-md">
        <LastUpdatedTime
          overrideTotalItems={totalItems}
          lastMenuUpdate={dispensary.lastMenuUpdate}
          className="pb-2 container"
        />
        <MenuFilters
          availableFilters={availableFilters}
          loading={loading}
          mobileClassName="mt-md"
        >
          {isCanadianDispensary && (
            <SearchAd
              className="my-xl"
              page="store"
              tag="menu_upper"
              customTargeting={{ dispensary: dispensary.slug }}
            />
          )}
          <MenuSortDropDown
            availableSorts={availableSorts}
            className="mb-md"
            totalItems={totalItems}
          />
          {!hasActiveFilter && (
            <CategoryCarousel
              dispensaryRetailType={dispensary.retailType}
              dispensarySlug={dispensary.slug}
              className="mb-lg"
            />
          )}
          <DealsBanner banner={dealsBanner} />
          <PromotedBrandCarousel
            brandPlacement={brandPlacement}
            cardsPerSlide={3}
            className="mb-section mt-xl"
            dispensary={dispensary}
          />
          {!hasActiveFilter && (
            <div data-testid="merchandising-tray">
              <ActiveDealsCarousel
                cardsPerSlide={3}
                className="mb-section mt-xl"
                deals={deals}
                dispensary={dispensary}
              />
              <StaffPicksCarousel
                cardsPerSlide={3}
                className="mb-section mt-xl"
                dispensaryId={dispensary.id}
                dispensaryRetailType={dispensary.retailType}
                dispensarySlug={dispensary.slug}
                filterable
                maxCardCount={6}
              />
            </div>
          )}
          <StrainEffectCopy />
          <DispensaryMenuItems
            hasActiveFilter={hasActiveFilter}
            menuItems={menuItems as MenuItem[]}
            loading={loading}
          />
          <PageNavigation
            currentPage={page}
            totalPages={totalPages}
            onNext={() => handleUpdatePage(page + 1)}
            onPrev={() => handleUpdatePage(page - 1)}
          />
        </MenuFilters>
        <MedicalDisclaimers
          usDisclaimer={DISPENSARY_MEDICAL_DISCLAIMER}
          className="container mt-xxl"
        />
      </div>
    </div>
  );
};

export default MenuContainer;
