import React, { memo } from "react";
import classNames from "classnames";
import merge from "lodash/merge";

import useAdSlot from "./useAdSlot";

/**
 * Google Publisher Tag wrapper for displaying Google Ad Manager ads.
 * Documentation
 * - https://developers.google.com/publisher-tag/reference
 * - https://developers.google.com/publisher-tag/guides/get-started
 *
 * Fluid ad example:
 *
 * <GoogleAd adPath="/13212770/home/full_takeover" />
 *
 * Sized ad example:
 *
 * <GoogleAd
 *   adPath="/13212770/strain/explore_playlist"
 *   sizes={{ desktop: [728, 90], mobile: [320, 50] }}
 *   targeting={{
 *      strain: slug,
 *      section: "strain",
 *      zone: "abvf"
 *    }}
 * />
 *
 * Profit!! 💰
 *
 * Note: this implementation does not support companionAdService yet https://leafly.atlassian.net/browse/AE-747
 */

export const MOBILE_BREAKPOINT: googletag.SingleSizeArray = [0, 0];
export const DESKTOP_BREAKPOINT: googletag.SingleSizeArray = [992, 0];

export const getDefaultSize = (sizes: Record<string, googletag.GeneralSize>) =>
  sizes.default || sizes.desktop.length ? sizes.desktop : sizes.mobile;

const stringifySizeArray = (arr: googletag.GeneralSize) => {
  if (!arr) {
    return "undefined";
  }
  if (arr[0] === undefined) {
    return "[]";
  }
  return typeof arr[0] === "string" ? `["${arr}"]` : `[${arr}]`;
};

export type Props = {
  /**
   * Full ad unit path with the network code and unit code.
   * Further reading https://developers.google.com/publisher-tag/reference#googletag.defineSlot
   */
  adPath: string;
  className?: string;
  /**
   * Optional (deprecated); use this prop in order to specify a unique ID for
   * the ad placement. This should no longer be required, as React will generate
   * a globally-unique ID for the ad placement without additional intervention.
   */
  idOverride?: string;
  /**
   * Display breakpoints required for non-fluid ad units.
   *
   * Examples
   *
   * <GoogleAd adPath="/13212770/strain/explore_playlist" sizes={{ desktop: [728, 90], mobile: [320, 50] }} />
   * <GoogleAd adPath="/13212770/strain/explore_playlist" sizes={{ desktop: [300, 250], mobile: [300, 250] }} />
   */
  sizes?: Record<string, googletag.GeneralSize>;
  style?: React.CSSProperties;
  /**
   * Metadata tagging to collect analytics in GAM
   *
   * Example
   *
   * <GoogleAd
   *   adPath="/13212770/strain/explore_playlist"
   *   targeting={{
   *     strain: slug,
   *     section: "strain",
   *     zone: "abvf"
   *   }}
   * />
   */
  targeting?: Record<string, string | string[] | undefined>;
  /*
   * Turns on lazyloading for ads on specific pages
   */
  enableLazyLoad?: boolean;
};

const GoogleAd: React.FC<Props> = ({
  adPath,
  className = "",
  idOverride,
  sizes,
  style,
  targeting = {},
  enableLazyLoad,
}) => {
  const isFluid = !sizes;

  sizes = merge(
    {
      desktop: [],
      desktop_breakpoint: DESKTOP_BREAKPOINT,
      mobile: [],
      mobile_breakpoint: MOBILE_BREAKPOINT,
    },
    sizes,
  );

  const { id } = useAdSlot({
    adPath,
    enableLazyLoad,
    idOverride,
    isFluid,
    sizes,
    targeting,
  });

  let adDataAttributes: Record<string, string> = {
    "data-default": isFluid
      ? `["fluid"]`
      : stringifySizeArray(getDefaultSize(sizes)),
    "data-enable-lazy-load": enableLazyLoad ? "true" : "false",
    // Set to false initially, slotRenderEnded listener will update if ad is rendered
    "data-rendered": "false",
    "data-slot": adPath,
    "data-testid": id,
  };

  if (!isFluid) {
    adDataAttributes = {
      ...adDataAttributes,
      "data-desktop": stringifySizeArray(sizes.desktop),
      "data-mobile": stringifySizeArray(sizes.mobile),
    };
  }

  return (
    <div
      aria-hidden="true"
      key={id}
      id={id}
      className={classNames(
        "flex justify-center hidden--screenshot",
        className,
      )}
      style={style}
      {...adDataAttributes}
    />
  );
};

export default memo(GoogleAd);
