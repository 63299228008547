import { useMemo } from "react";
import orderBy from "lodash/orderBy";

const SORT_OPTIONS: Record<string, { order: number; value: "asc" | "desc" }[]> =
  {
    cbd_content: [
      { order: 5, value: "asc" },
      { order: 6, value: "desc" },
    ],
    price: [
      { order: 1, value: "asc" },
      { order: 2, value: "desc" },
    ],
    thc_content: [
      { order: 3, value: "asc" },
      { order: 4, value: "desc" },
    ],
  };

const useSortDropDown = (availableFilters: string[]) => {
  const selectMenuSorts = useMemo(() => {
    const sortOptions = {
      label: "Sort",
      name: "sort",
      resetLabel: "Recommended",
      subLabel: "Recommended",
      values: [] as { value: string; order: number }[],
    };

    availableFilters.forEach((sortOption: string) => {
      const sortOptionSettings = SORT_OPTIONS[sortOption];
      if (sortOptionSettings) {
        sortOptionSettings?.forEach(({ value, ...settings }) => {
          sortOptions.values.push({
            value: `${sortOption}:${value}`,
            ...settings,
          });
        });
      }
    });

    return {
      ...sortOptions,
      values: orderBy(sortOptions.values, ["order"], "asc"),
    };
  }, [availableFilters]);

  return {
    selectMenuSorts,
  };
};

export default useSortDropDown;
