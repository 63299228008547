import classnames from "classnames";

import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";

import Button from "components/botanic/Button";
type Props = {
  className?: string;
  handleResetAllFilters: () => void;
};

const NoResults: React.FC<Props> = ({ className, handleResetAllFilters }) => {
  const { publishEvent } = useEventTracker();
  return (
    <div className={classnames("flex flex-col items-center pt-xxl", className)}>
      <p className="font-bold text-md">Darn! No results found.</p>
      <p>Try selecting fewer options</p>
      <p className="italic">or</p>
      <Button
        onClick={() => {
          publishEvent({
            action: Action.click,
            category: Category.dispensaryMenuFilter,
            label: "reset all filters",
          });
          handleResetAllFilters();
        }}
      >
        reset all filters
      </Button>
    </div>
  );
};

export default NoResults;
