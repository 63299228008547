import React, { useEffect, useId } from "react";

import GoogleAd, {
  DESKTOP_BREAKPOINT,
  getDefaultSize,
  MOBILE_BREAKPOINT,
} from "components/GoogleAd/GoogleAd";

const useAdSlot = ({
  adPath,
  idOverride,
  isFluid,
  sizes,
  targeting = {},
  enableLazyLoad,
}: {
  enableLazyLoad?: boolean;
  isFluid?: boolean;
  sizes: Record<string, googletag.GeneralSize>;
} & Pick<
  React.ComponentProps<typeof GoogleAd>,
  "adPath" | "sizes" | "targeting" | "idOverride"
>) => {
  const uniqueId = useId();
  /**
   * This ID must be unique on the page in order for Google to display
   * the ad correctly. It should not be required to override the value
   * generated by React, but `idOverride` is preserved here for backwards
   * compatibility with the existing uses of this component.
   */
  const id = idOverride ? `gpt-${idOverride}` : uniqueId;
  const size: googletag.GeneralSize = isFluid
    ? ["fluid"]
    : getDefaultSize(sizes);

  useEffect(() => {
    let slot: googletag.Slot | null;
    let resizeHandler: () => void;
    let orientationChangeHandler: () => void;
    let renderEndedHandler: (
      event: googletag.events.SlotRenderEndedEvent,
    ) => void;

    window.googletag = window.googletag || { cmd: [] };
    window.googletag.cmd.push(() => {
      slot = window.googletag.defineSlot(adPath, size, id);

      if (isFluid) {
        slot?.setCollapseEmptyDiv(true);
      } else {
        slot?.defineSizeMapping(
          window.googletag
            .sizeMapping()
            .addSize(MOBILE_BREAKPOINT, sizes.mobile)
            .addSize(DESKTOP_BREAKPOINT, sizes.desktop)
            .build(),
        );
      }

      /**
       * Adding the `pubads` service must be done after `collapseEmptyDivs` is enabled.
       * See: https://developers.google.com/publisher-tag/reference#googletag.PubAdsService_collapseEmptyDivs
       */
      slot?.addService(window.googletag.pubads());

      Object.entries(targeting).forEach(([key, value]) => {
        if (key && value) {
          slot?.setTargeting(key, value);
        }
      });

      enableLazyLoad && window.googletag.pubads().enableLazyLoad();

      /**
       * Enables all GPT services that have been defined for ad slots on the page.
       * This is also called in GooglePublisherTagScript. Might it be possible to remove here?
       */
      window.googletag.enableServices();
      window.googletag.display(id);

      // Refresh ads on window resize to update responsive ad sizes
      let originalWidth = window.innerWidth;
      resizeHandler = () => {
        const newWidth = window.innerWidth;
        if (
          (newWidth > 1024 && originalWidth < 1025) ||
          (newWidth < 1025 && originalWidth > 1024)
        ) {
          originalWidth = newWidth;
          if (slot) {
            window.googletag.pubads().refresh([slot]);
          }
        }
      };
      window.addEventListener("resize", resizeHandler);

      // Refresh ads on device orientation change to update responsive ad sizes
      orientationChangeHandler = () => {
        if (slot) {
          window.googletag.pubads().refresh([slot]);
        }
      };
      document.addEventListener("orientationchange", orientationChangeHandler);

      renderEndedHandler = (event) => {
        /**
         * It's only possible to register this handler globally on the pubads
         * service, so we need to check that the slot that's triggering this
         * event is the same slot that's being managed by this instance of
         * the GoogleAd component.
         */
        if (event.slot === slot) {
          document
            .getElementById(event.slot.getSlotElementId())
            ?.setAttribute("data-rendered", (!event.isEmpty).toString());
        }
      };
      window.googletag
        .pubads()
        .addEventListener("slotRenderEnded", renderEndedHandler);
    });

    return () => {
      if (slot) {
        /**
         * Slots must be destroyed when the component is unmounted, in order to
         * support client-side routing. If slots are not cleaned up between
         * route changes, returning to the original page will fail to load any
         * previously-loaded ads, as google tags believes that the ads are still
         * loaded on the page.
         */
        window.googletag.destroySlots([slot]);
      }

      if (resizeHandler) {
        window.removeEventListener("resize", resizeHandler);
      }

      if (orientationChangeHandler) {
        document.removeEventListener(
          "orientationchange",
          orientationChangeHandler,
        );
      }

      if (renderEndedHandler) {
        /**
         * ad blockers like uBlock remove some event listeners, meaning "removeEventListener" may not exist at the
         * time of invocation. Here we verify via optional chaining whether the method exists before calling it.
         */
        window.googletag
          ?.pubads?.()
          ?.removeEventListener?.("slotRenderEnded", renderEndedHandler);
      }
    };
  }, [adPath, id, JSON.stringify(sizes), JSON.stringify(targeting)]);

  return { id };
};

export default useAdSlot;
