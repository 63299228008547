export const updatePageParam = (url: string, page: number, domain: string) => {
  const urlObj = new URL(`${domain}${url}`);
  const params = new URLSearchParams(urlObj.search);

  if (page === 1) {
    params.delete("page");
  } else {
    params.set("page", page.toString());
  }

  const queryString = params.toString();
  return queryString
    ? `${domain}${urlObj.pathname}?${queryString}`
    : `${domain}${urlObj.pathname}`;
};
