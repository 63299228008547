import consumerApi from "api/services/consumer-api";
import logError from "utils/logError";

export type DealBanner = {
  id: number;
  header: string;
  subtitle: string;
};

const getDealBanner = async (dispensarySlug: string, menuType?: string) => {
  let medicalStatus;
  switch (menuType?.toLowerCase()) {
    case "rec":
      menuType = "is_recreational";
      break;
    case "med":
      menuType = "is_medical";
      break;
    default:
      break;
  }

  try {
    let url = `/api/dispensaries/v1/${dispensarySlug}/deals/banner`;
    if (medicalStatus) {
      url += `?filter%5Bmedical_status%5D=${medicalStatus}`;
    }

    const {
      data: { data },
    } = await consumerApi.get<{ data: DealBanner[] }>(url);

    return data.length ? data[0] : null;
  } catch (e) {
    logError(e.message, {
      functionName: "getDealBanner",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return null;
  }
};

export default getDealBanner;
