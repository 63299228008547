import classnames from "classnames";

import useFilters from "hooks/useFilters";

import CloseIcon from "components/Icons/x.svg";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  activeFilters: any;
  wrap?: boolean;
  className?: string;
};

const ActiveFilterTags: React.FC<Props> = ({
  activeFilters,
  wrap,
  className,
}) => {
  const { selectFilterValue, addDispensaryFilter } = useFilters();

  // @ts-ignore (fix me please, do not replicate)
  const getDisplayValue = (name, value) => {
    if (name === "price") {
      const { min = 0, max = 100 } = value;
      return `$${min} - $${max}${max === 100 ? "+" : ""}`;
    } else if (name === "thc_pips" || name === "cbd_pips") {
      const { min = 0, max = 5 } = value;
      return `${name.split("_")[0].toUpperCase()} ${min}-${max}`;
    }

    return value;
  };

  // @ts-ignore (fix me please, do not replicate)
  const removeFilter = (e) => {
    const { name, value } = e.currentTarget.dataset;

    if (name && value) {
      const currentValue = selectFilterValue(name);
      const newValue =
        ["thc_pips", "cbd_pips", "price"].some((filter) => filter === name) ||
        value === currentValue
          ? undefined
          : (currentValue as string[]).filter(
              (selection) => selection !== value,
            );

      addDispensaryFilter(name, newValue);
    }
  };

  if (!activeFilters.length) {
    return null;
  }

  return (
    <div
      data-testid="active-filter-tags"
      className={classnames(
        "flex -mr-sm font-bold text-xs",
        { "flex-wrap -mb-sm": wrap },
        className,
      )}
    >
      {
        // @ts-ignore (fix me please, do not replicate)
        activeFilters.map(({ name, values }) =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
          values.map((value: any) => {
            const displayValue = getDisplayValue(name, value);
            return (
              <div
                key={displayValue}
                className={classnames(
                  "flex flex-shrink-0 items-center bg-green-20 border-green-40 border rounded py-xs px-md mr-sm",
                  { "mb-sm": wrap },
                )}
                data-name={name}
                data-value={displayValue}
                onClick={removeFilter}
                onKeyPress={removeFilter}
                role="button"
                tabIndex={0}
              >
                {displayValue}
                <CloseIcon
                  height="12"
                  width="12"
                  className="ml-xs text-default"
                />
              </div>
            );
          }),
        )
      }
    </div>
  );
};

export default ActiveFilterTags;
